const React = require('react')
const chakra = require('@chakra-ui/core')
const ThemeProvider = chakra.ThemeProvider
const CSSReset = chakra.CSSReset

exports.wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider>
      <CSSReset />
      {element}
    </ThemeProvider>
  )
}